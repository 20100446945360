import { Page } from '../../presenters/pages/index';
import { AppContext, AppContextSpec, useAsyncEffect } from '../../App';
import { strings } from '../../i18n/strings'
import {
    getSubzoneStateAuditColumnInformation,
    searchSubzoneStateAudit
} from '../../domain/repository/SubzoneStateLeadTimeRepository';
import React, {useContext, useEffect, useState} from 'react';
import { DynamicDatatable } from '../../../../graphics/tables/DynamicDatatable';
import { HeadCell } from '../../../../graphics/tables/data/HeadCell';
import { TableRowData } from '../../../../graphics/tables/data/TableRowData';
import { Order } from "../../../../graphics/tables/operations/sort";
import { useOktaAuth } from '../../../../okta-react';
import {Alert} from "@material-ui/lab";
import {SubzoneStateAuditTableStructure} from "../../domain/indexes/SubzoneStateAuditIndexes";

const DEFAULT_PAGINATION_LIMIT : number = (process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) ? parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) : 20;

// Interfaces & types
type Record = SubzoneStateAuditTableStructure.RecordOf<SubzoneStateAuditTableStructure.SubzoneStateAudit>;
interface RecordRepresentation extends Record, TableRowData { };

const tableFields: Array<keyof Omit<Record, 'rev'> & string> = [
    'rev',
    'id',
    'revtype',
    'subzoneCode',
    'state',
    'modifiedBy',
    'createdDate',
    'modifiedDate'
];

const wordingsDatatable = strings.datatable;
const wordingSubzoneStateAuditPage = strings.page.subzoneStateAudit;
const wordingSubzoneStatePage = strings.page.subzone;

export const SubzoneStateAuditPage= () => {

    const appContext = useContext<AppContextSpec>(AppContext);
    const { authState } = useOktaAuth();
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<SubzoneStateAuditTableStructure.ResponseOf<SubzoneStateAuditTableStructure.SubzoneStateAudit>>();
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const [tableDataDefinition, setTableDataDefinition] = useState<SubzoneStateAuditTableStructure.ResponseOfColumn<SubzoneStateAuditTableStructure.SubzoneStateAudit>>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<string>(tableFields[0]);
    const [updateSignal, setUpdateSignal] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<string>('');

    const currentPage = wordingSubzoneStatePage.title;

    const handleClose = () => {
        setOpen(false);
        setMessage('');
    };

    useEffect(() => {
        if(message) {
            setOpen(true);
        }
    }, [message]);

    // Define configuration and try to override by algo configuration
    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: field,
        required: true,
        information: field,
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: field,
        referenceColumnLabel: field,
        disablePadding: false,
        searchValue: "",
    }));

    //Get HeadCells to display tab names
    useEffect(() => {
        (async () => {
            try {
                const headCellConfigValues: SubzoneStateAuditTableStructure.ResponseOfColumn<SubzoneStateAuditTableStructure.SubzoneStateAudit> = await getSubzoneStateAuditColumnInformation<SubzoneStateAuditTableStructure.SubzoneStateAudit>(authState!);

                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.massEditable = config.massEditable;
                    cell.reference = config.reference;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });
                setHeadCellsConfig(headCells);
                setTableDataDefinition(headCellConfigValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const getAndRefreshRecords = (newList ?: HeadCell<RecordRepresentation>[]) => {

        appContext.displayLoader(true);

        let headCellsConfigForGetAndRefresh = (newList) ? newList : headCellsConfig;

        let formattedSearchValues: SubzoneStateAuditTableStructure.FormattedSearchValue[] = headCellsConfigForGetAndRefresh
            .filter(cell => cell.label && cell.searchValue)
            .map(cell  => {
                return {
                    key: cell.label,
                    value : cell.searchValue
                };
            });

        const updateState = (subzoneStateAuditValues: SubzoneStateAuditTableStructure.ResponseOf<SubzoneStateAuditTableStructure.SubzoneStateAudit>) => {
            setTableData(subzoneStateAuditValues);
            setRowData(subzoneStateAuditValues.values);
            console.log(subzoneStateAuditValues.values)
            if (page !== subzoneStateAuditValues.pagination.page) {
                setPage(subzoneStateAuditValues.pagination.page);
            }
        }

        (async () => {
            try {
                const subzoneStateAuditValues: SubzoneStateAuditTableStructure.ResponseOf<SubzoneStateAuditTableStructure.SubzoneStateAudit> =
                    await searchSubzoneStateAudit<Record>(authState!, formattedSearchValues, page, limit, order, orderBy);
                if (page !== subzoneStateAuditValues.pagination.page) {
                    setPage(subzoneStateAuditValues.pagination.page);
                }

                updateState(subzoneStateAuditValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();

        appContext.displayLoader(false)
    }

    useAsyncEffect(async () => {
            await getAndRefreshRecords();
        }, [page, limit, order, orderBy, updateSignal]
    );

    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = ( newList: HeadCell<RecordRepresentation>[]) => {
        setPage(1);
        headCellsConfig.forEach((cell : HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });

        getAndRefreshRecords(headCellsConfig);
    }

    const onChangeVisibilityColumnDialog = (newList: HeadCell<RecordRepresentation>[]) => {
        const newHeadCells = newList.slice();
        setHeadCellsConfig(newHeadCells);
    }

    const onReorderDialogueAction = (newList: HeadCell<RecordRepresentation>[]) => {
        const newHeadCells = newList.slice();
        setHeadCellsConfig(newHeadCells);
    }

    return (
        <Page title={wordingSubzoneStateAuditPage.title} descriptionLine1={wordingSubzoneStateAuditPage.description.line1} descriptionLine2={wordingSubzoneStateAuditPage.description.line2}>
            {(
                <>
                    {message && (
                        <Alert severity="warning" onClose={() => {setMessage('')}}>{message}</Alert>
                    )}
                    <DynamicDatatable
                        stickyHeader={true}
                        headCells={headCellsConfig}
                        data={rowData}
                        initialOrderBy={tableFields[0]}
                        showPadding={false}
                        onChangeVisibilityColumnDialog={onChangeVisibilityColumnDialog}
                        onReorderDialogueAction={onReorderDialogueAction}
                        allowReordering={{
                            modal: {
                                title: wordingsDatatable.reorderColumns.modal.title,
                                applyButtonLabel: wordingsDatatable.reorderColumns.modal.applyButtonLabel,
                            },
                            triggerButtonLabel: wordingsDatatable.reorderColumns.modal.title}}
                        allowChangeVisibilityColumn={{
                            modal: {
                                title: wordingsDatatable.changeVisibilityColumns.modal.title,
                                applyButtonLabel: wordingsDatatable.changeVisibilityColumns.modal.applyButtonLabel,
                            },
                            triggerButtonLabel: wordingsDatatable.changeVisibilityColumns.modal.title}}
                        paginationOptions={(() => ({
                            page,
                            limit,
                            order,
                            orderBy,
                            total: tableData?.pagination?.total || 0,
                            rowsPerPageValues: [
                                { nb: DEFAULT_PAGINATION_LIMIT / 2, isDefault: false },
                                { nb: DEFAULT_PAGINATION_LIMIT, isDefault: true },
                                { nb: DEFAULT_PAGINATION_LIMIT * 2, isDefault: false },
                                { nb: DEFAULT_PAGINATION_LIMIT * 4, isDefault: false },
                            ],
                            meetNbRowsPerPage: false,
                            rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                            onPaginationChange: handlePaginationChange,
                            onHandleSortChange: handleSortChange,
                            onMultiCellsSearch: handleMultiCellsSearch
                        }))()}
                        showHistory={[{
                            showHistoryButton: true,
                            urlToViewHistory: '/subzone',
                            linkToParentPage: true,
                            icon: 'HistoryIcon'
                        }]}
                        pageTitles={[currentPage]}
                    />
                </>
            )}
        </Page>
    );
};
