import { Order } from "../../../../graphics/tables/operations/sort";
import { ApiDatasource } from "../../adapters/io/datasources/ApiDatasource";
import { unwrap } from "../../adapters/io/network/HttpResponse";
import { formatText } from "../../adapters/io/network/utils";
import { ApiDatasourceSpec } from "../../core/datasource";
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {CCZoneTableStructure} from "../indexes/CCZoneIndexes";
import {CCZoneAuditTableStructure} from "../indexes/CCZoneAuditIndexes";

export const searchCCZone = <T>(
    authState: AuthState,
    search: CCZoneTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<CCZoneTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<CCZoneTableStructure.ResponseOf<T>, CCZoneTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointCCZone.endPoint.concat(EndpointCCZone.searchCCZone), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getCCZoneColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<CCZoneTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<CCZoneTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointCCZone.endPoint.concat(EndpointCCZone.getCCZoneColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const createCCZone = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointCCZone.endPoint.concat(EndpointCCZone.createCCZone), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editCCZone = <RecordType>(
    authState: AuthState,
    id: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointCCZone.endPoint.concat(EndpointCCZone.editCCZone), {}) + `?id=${id}`, record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deleteCCZone = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) => datasource.remote.deleteData(uriFormatter(EndpointCCZone.endPoint.concat(EndpointCCZone.deleteCCZone), {}) + `?id=${id}`, buildHeader(authState), new Map());

export const searchCCZoneAudit = <T>(
    authState: AuthState,
    search: CCZoneAuditTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<CCZoneAuditTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<CCZoneAuditTableStructure.ResponseOf<T>, CCZoneAuditTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointCCZone.endPoint.concat(EndpointCCZone.searchCCZoneAudit), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getCCZoneAuditColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<CCZoneAuditTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<CCZoneAuditTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointCCZone.endPoint.concat(EndpointCCZone.getCCZoneAuditColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointCCZone = {
    endPoint: '/end-point/cc-zone',
    searchCCZone: '/search',
    getCCZoneColumnInformation: '/column-information',
    createCCZone: '',
    editCCZone: '',
    deleteCCZone: '',
    searchCCZoneAudit: '/audit/search',
    getCCZoneAuditColumnInformation: '/audit'
};