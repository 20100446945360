import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { SubzoneTableStructure } from '../indexes/SubzoneLeadTimeIndexes';
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {Order} from "../../../../graphics/tables/operations/sort";
import {SubzoneAuditTableStructure} from "../indexes/SubzoneAuditIndexes";

export const searchSubzone = <T>(
    authState: AuthState,
    search: SubzoneTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<SubzoneTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches: search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<SubzoneTableStructure.ResponseOf<T>, SubzoneTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointSubzone.endPoint.concat(EndpointSubzone.searchSubzone), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};
export const getSubzoneColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<SubzoneTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<SubzoneTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointSubzone.endPoint.concat(EndpointSubzone.getSubzoneColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const createSubzone = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointSubzone.endPoint.concat(EndpointSubzone.createSubzone), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const editSubzone = <RecordType>(
    authState: AuthState,
    code: string | number,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointSubzone.endPoint.concat(EndpointSubzone.editSubzone), {}) + `?code=${code}`, record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deleteSubzone = (
    authState: AuthState,
    code: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) : Promise<void> => {

    return datasource.remote
        .deleteData(uriFormatter(EndpointSubzone.endPoint.concat(EndpointSubzone.deleteSubzone), {}) + `?code=${code}`, buildHeader(authState), new Map())
        .then(() => {});
};

export const searchSubzoneAudit = <T>(
    authState: AuthState,
    search: SubzoneAuditTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<SubzoneAuditTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<SubzoneAuditTableStructure.ResponseOf<T>, SubzoneAuditTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointSubzone.endPoint.concat(EndpointSubzone.searchSubzoneAudit), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const getSubzoneAuditColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<SubzoneAuditTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<SubzoneAuditTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointSubzone.endPoint.concat(EndpointSubzone.getSubzoneAuditColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointSubzone = {
    endPoint: '/end-point/subzone',
    getSubzones: '',
    searchSubzone: '/search',
    getSubzoneColumnInformation: '/column-information',
    createSubzone: '',
    editSubzone: '',
    deleteSubzone:'',
    searchSubzoneAudit: '/audit/search',
    getSubzoneAuditColumnInformation: '/audit'
};